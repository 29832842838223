import React from 'react'

class EmailSignature extends React.Component {
  componentDidMount() {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href =
      'https://cdn.jsdelivr.net/npm/@tailwindcss/ui@latest/dist/tailwind-ui.min.css'
    document.head.appendChild(link)

    window.jQuery(function ($) {
      $('#btn-update').on('click', function () {
        const info = {
          firstname: $('#first-name').val(),
          lastname: $('#last-name').val(),
          title: $('#title').val(),
          phone: $('#phone').val(),
          // address1: $('#street-address1').val(),
          // address2: $('#street-address2').val(),
          // city: $('#city').val(),
          // state: $('#state').val(),
          // zip: $('#zip').val(),
        }

        $('#signature-first-last').html(`${info.firstname} ${info.lastname}`)
        $('#signature-title').html(info.title)
        $('#signature-phone-number').html(info.phone)
        // $('#signature-address1').html(info.address1);
        // $('#signature-address2').html(info.address2);
        // $('#signature-city-state-zip').html(`${info.city}, ${info.state}, ${info.zip}`);
      })

      $('#btn-select').on('click', function () {
        window
          .getSelection()
          .selectAllChildren(document.getElementById('signature'))
      })

      $('#btn-copy').on('click', function () {
        document.execCommand('copy')
      })
    })
  }

  render() {
    return (
      <div className="min-h-screen bg-white">
        <nav className="bg-white border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src="https://www.hyphametrics.com/images/logo-hd.png"
                    alt="Workflow logo"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src="https://www.hyphametrics.com/images/logo-hd.png"
                    alt="Workflow logo"
                  />
                </div>
                <div className="hidden sm:-my-px sm:ml-6 space-x-8 sm:flex"></div>
              </div>
            </div>
          </div>
        </nav>

        <div className="py-10">
          <header>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Hyphametrics E-mail Signature
              </h1>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="px-4 py-8 sm:px-0">
                <div>
                  <div>
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                      <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                        <table
                          style={{
                            width: 500,
                            fontSize: '10pt',
                            fontFamily: 'sans-serif',
                          }}
                          cellPadding="0"
                          cellSpacing="0"
                          border="0"
                          id="signature"
                        >
                          <tbody>
                            <tr>
                              <td
                                width="100"
                                align="center"
                                style={{
                                  fontSize: '10pt',
                                  fontFamily: 'sans-serif',
                                  width: '100px',
                                  textAlign: 'center',
                                  verticalAlign: 'top',
                                }}
                                valign="top"
                                rowSpan="1"
                              >
                                <p>
                                  <img
                                    src="https://www.hyphametrics.com/images/signature-hypha.png"
                                    alt="Hyphametrics"
                                    width="87"
                                    border="0"
                                    style={{
                                      border: 0,
                                      height: 'auto',
                                      width: '87px',
                                    }}
                                  />
                                </p>
                              </td>
                              <td width="20" style={{width: '20px'}}>
                                &nbsp;
                              </td>
                              <td>
                                <table cellPadding="0" cellSpacing="0">
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: '10pt',
                                          color: '#0079ac',
                                          fontFamily: 'sans-serif',
                                          width: '210px',
                                          paddingBottom: '3px',
                                          verticalAlign: 'top',
                                          lineHeight: 1.2,
                                        }}
                                        valign="top"
                                      >
                                        <span
                                          style={{
                                            fontSize: '0.8rem',
                                            fontFamily: 'sans-serif',
                                            color: '#002a00',
                                          }}
                                          id="signature-first-last"
                                        >
                                          First Last
                                        </span>
                                        <br />
                                        <em
                                          style={{
                                            fontFamily: 'sans-serif',
                                            fontSize: '0.8rem',
                                            color: '#002a00',
                                          }}
                                          id="signature-title"
                                        >
                                          Title
                                        </em>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: '2px solid #0D288B',
                                          fontSize: '0.8rem',
                                          color: '#444444',
                                          fontFamily: 'sans-serif',
                                          paddingTop: '10px',
                                          verticalAlign: 'top',
                                          lineHeight: 1.2,
                                        }}
                                        valign="top"
                                      >
                                        <span style={{color: '#6ebc6e'}}></span>
                                        <span
                                          style={{
                                            fontSize: '0.8rem',
                                            fontFamily: 'sans-serif',
                                            color: '#002a00',
                                          }}
                                          id="signature-phone-number"
                                        >
                                          Phone number
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td
                                        style={{
                                          fontSize: '10pt',
                                          fontFamily: 'sans-serif',
                                          paddingBottom: '5px',
                                          paddingTop: '5px',
                                          verticalAlign: 'top',
                                        }}
                                        valign="top"
                                      >
                                        <a
                                          href="https://www.hyphametrics.com/"
                                          target="_blank"
                                          rel="noopener"
                                          style={{
                                            color: '#002a00',
                                            fontSize: '0.8rem',
                                            textDecoration: 'none',
                                          }}
                                        >
                                          Website
                                        </a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <a
                                          href="https://www.linkedin.com/company/hyphametrics/"
                                          target="_blank"
                                          rel="noopener"
                                          style={{
                                            color: '#002a00',
                                            fontSize: '0.8rem',
                                            textDecoration: 'none',
                                          }}
                                        >
                                          LinkedIn
                                        </a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <a
                                          href="https://twitter.com/HyphaMetrics"
                                          target="_blank"
                                          rel="noopener"
                                          style={{
                                            color: '#002a00',
                                            fontSize: '0.8rem',
                                            textDecoration: 'none',
                                          }}
                                        >
                                          Twitter
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4">
                                <p
                                  style={{
                                    fontFamily: 'sans-serif',
                                    fontSize: '0.6rem',
                                    color: '#666',
                                    margin: '1rem 0',
                                    lineHeight: '0.8rem',
                                  }}
                                >
                                  The information transmitted by this email is
                                  intended only for the person or entity to
                                  which it is addressed. This email may contain
                                  proprietary, business-confidential and/or
                                  privileged material. If you are not the
                                  intended recipient of this message, be aware
                                  that any use, review, retransmission,
                                  distribution, reproduction or any action taken
                                  in reliance upon this message is strictly
                                  prohibited. If you received this in error,
                                  please contact the sender and delete the
                                  material from all computers.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <button
                          type="submit"
                          id="btn-select"
                          className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                        >
                          Select
                        </button>
                        <button
                          type="submit"
                          id="btn-copy"
                          className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-8 border-t border-gray-200 pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Signature Information
                        </h3>
                        <p className="mt-1 text-sm leading-5 text-gray-500">
                          Complete the following information and then press the
                          "Update signature" button.
                        </p>
                      </div>
                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            First name
                          </label>
                          <div className="mt-1 rounded-md shadow-sm">
                            <input
                              id="first-name"
                              className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Last name
                          </label>
                          <div className="mt-1 rounded-md shadow-sm">
                            <input
                              id="last-name"
                              className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="title"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Title
                          </label>
                          <div className="mt-1 rounded-md shadow-sm">
                            <input
                              id="title"
                              type="text"
                              className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Phone number
                          </label>
                          <div className="mt-1 rounded-md shadow-sm">
                            <input
                              id="phone"
                              type="text"
                              placeholder="XXX.XXX.XXXX"
                              className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8 border-t border-gray-200 pt-5">
                    <div className="flex justify-end">
                      <span className="ml-3 inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          id="btn-update"
                          className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                        >
                          Update signature
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    )
  }
}

export default EmailSignature
