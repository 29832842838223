import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "../store/action/types";
import { css } from "@emotion/core";
import Header from "./includes/Header";
import Footer from "./includes/Footer";

class Contact extends Component {
  constructor() {
    super();
    this.state = {
      contact: {},
      contact_social: [],
      loading: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get(API_URL + "/contact-us")
      .then((result) => {
        if (result.data.success) {
          this.setState({
            contact: result.data.contact,
            contact_social: result.data.contact_social,
            loading: false,
          });
        }
      })
      .catch(function (error) {
        alert("Could not get contents");
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { contact, contact_social } = this.state;
    const numRows = contact_social.length;
    const override = css`
      display: inline-block;
      border-color: #002a00;
    `;

    return (
      <>
        <style>
          {`#root {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          
          .footer {
            flex: 1;
          }
          `}
        </style>
        <Header />
        {this.state.loading ? null : (
          <>
            <div className="abt-bg-top">
              <div className="container-fluid">
                <div className="row">
                  <div
                    className="col-md-12 nlrp"
                    style={{
                      backgroundColor: "#B6C5B6",
                      textAlign: "right",
                      maxHeight: "720px",
                    }}
                  >
                    <img
                      src="/images/_Strands_Comp_7.png"
                      alt="Hypha Roots"
                      class="img-fluid img-strand-comp-7"
                      style={{ maxHeight: 720 }}
                    />
                    <div className="contact-info-wrapper">
                      <div class="cnt-head">Contact</div>
                      <div className="d-flex">
                        <div className="contact-emaildata">Email:</div>
                        <a
                          href="mailto:info@HyphaMetrics.com"
                          class="contact-email"
                        >
                          info@HyphaMetrics.com
                        </a>
                      </div>
                      <div className="d-flex">
                        <div className="contact-emaildata">Press:</div>
                        <a
                          href="mailto:info@HyphaMetrics.com"
                          class="contact-email"
                        >
                          PR@HyphaMetrics.com
                        </a>
                      </div>
                      <div className="d-flex">
                        {contact_social.map((social, index) => (
                          <>
                            {index > 0 && (
                              <span
                                style={{
                                  color: "#fff",
                                  margin: "0 7px",
                                  fontSize: "1.3rem",
                                }}
                                class="align-self-center"
                              >
                                •
                              </span>
                            )}
                            <a href={social.social_link} class="contact-smedia">
                              {social.social_title}
                            </a>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <Footer />
      </>
    );
  }
}
export default Contact;
