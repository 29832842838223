import { SET_VIDEO } from "../action/types";

const initialState = {
    isLoaded : false,
    videoName : "",
    homeData : {},
    servPopup : []
};

export default (state = initialState , action) =>
{
    switch(action.type)
    {
        case SET_VIDEO:
            return {
                ...state,
                isLoaded : action.payload.isLoaded,
                videoName : action.payload.videoName,
                homeData : action.payload.homeData,
                servPopup : action.payload.servPopup
            }
        default:
            return state;
    }
}