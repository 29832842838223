import React from 'react'
import axios from 'axios'
import {API_URL} from '../store/action/types'
import parse from 'html-react-parser'
import {css} from '@emotion/core'
import CircleLoader from 'react-spinners/CircleLoader'
import OwlCarousel from 'react-owl-carousel'
// import 'owl.carousel/dist/assets/owl.carousel.css'
// import 'owl.carousel/dist/assets/owl.theme.default.css'
import Header from './includes/Header'
import Footer from './includes/Footer'
import {Link} from 'react-router-dom'
// import "../assets/css/js_composer.min.css";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="abt-tp-content" style={{marginTop: '4rem'}}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>
                  The purpose of this Privacy Policy is to explain our data
                  practices for personal information collected through this
                  website. This Privacy Policy only applies to personal
                  information collected through the hyphametrics.com website,
                  and other websites owned by HyphaMetrics, Inc. and its
                  subsidiaries, and related mobile applications (the “website”
                  or collectively, “websites”). This website is operated by
                  HyphaMetrics, Inc. (“we” “us” or “our”).
                </p>
                <p>Collection Of Your Personal Information on this Website</p>
                <p>
                  This website is primarily directed to our customers,
                  prospective customers, and others interested in using our
                  services or working with us. When you visit our website, we
                  may collect certain information from you, such as: 
                </p>
                <p>
                  (1) information that identifies you individually (such as
                  name, email address, phone number, and address), which you may
                  voluntarily supply, including when you request information
                  from us or request to be contacted (e.g., you fill out our
                  contact form on our Contact page), when you sign up for our
                  newsletter, and 
                </p>
                <p>
                  (2) information collected passively, as you navigate our
                  websites, such as a unique “cookie” ID or Internet Protocol
                  (“IP”) address. We describe below how we use each of those
                  categories of information.
                </p>
                <p>Use of your Information Collected on Our Website</p>
                <p>
                  We use your personal information to: (1) improve our website;
                  (2) contact you; (3) respond to your requests; (4) analyze how
                  you use the website, (5) respond to legal requirements,
                  requests and actions, protect the security of our business,
                  and enforce our terms, and (6) engage in other uses described
                  under “How We Share Your Information” below. If you are a
                  panelist, we also use your information as described in the
                  applicable panelist privacy notice. We may also use the
                  information for any purpose for which it was collected. For
                  instance, if we conduct a survey on our website, we will use
                  that information to analyze the survey results and apply them
                  to our marketing or business purposes. We may use technologies
                  to track your use of our website and to tailor your web
                  experience. We may, for instance, use technologies to send you
                  display advertising when you view our website or other
                  websites. These technologies include “cookies,” which we
                  describe immediately below. We may combine any information
                  that we collect from you, whether collected directly or
                  passively from you.
                </p>
                <p>Use of Cookies on Our Website</p>
                <p>
                  Cookies and local storage may be set and accessed on your
                  computer. Upon your first visit to the Services, a cookie or
                  local storage may be sent to your computer that uniquely
                  identifies your browser. “Cookies” and local storage are small
                  files containing a string of characters that is sent to your
                  computer&#39;s browser and stored on your device when you
                  visit a website. Many major Web services use cookies to
                  provide useful features for their users. Each Web site can
                  send its own cookie to your browser. Most browsers are
                  initially set up to accept cookies. You can reset your browser
                  to refuse all cookies or to indicate when a cookie is being
                  sent; however, if you reject cookies, you will not be able to
                  take full advantage of our websites. Additionally, if you
                  clear all cookies on your browser at any point after setting
                  your browser to refuse all cookies or indicate when a cookie
                  is being sent, you will have to again reset your browser to
                  refuse all cookies or indicate when a cookie is being sent. We
                  may use both session cookies (which expire once you close your
                  browser) and persistent cookies (which stay on your computer
                  until you delete them or until they expire). We, and
                  third-party cookie-based ad platforms we may work with, use
                  cookies to automatically collect information about your
                  computer when you visit our websites, and automatically store
                  it in log files. This may include the type of browser software
                  you use, the operating system you are running, the website
                  that referred you, and your IP address (from which your
                  general location may be inferred). We may use our own
                  proprietary cookies (set from our own web domain), as well as
                  cookies hosted by third-party vendors such as ad or analytics
                  vendors and partners. Third-party cookies generally employ
                  unique identifiers (often randomly generated) to help
                  advertisers customize users’ web experiences, based on the
                  users’ likely interests. They may be used to tailor ad content
                  on websites you visit. They may collect data such as your
                  browser type, your operating system, Web pages visited, time
                  of visits, content viewed, and other clickstream data. We use
                  these cookies to retarget information and offers to you about
                  our services: for instance, if you view a page on our website
                  regarding an event or conference we are hosting, we might use
                  a third-party ad partner to send you an ad about the event
                  when you visit another website.  When you visit our websites
                  or open an email you have received from us, we or one of our
                  ad partners may place or recognize a unique cookie on your
                  browser. These cookies enable us to send you ads about our
                  services, customize our services and gather analytics about
                  our web traffic. Additionally, emails we send to you may
                  contain a bit of code known as a “web beacon,” also called a
                  “pixel” or “web pixel.” This code helps us understand the time
                  and date when a user opened an email sent by us, and when
                  he/she has used a link within the email to visit a website,
                  thus allowing us to collect analytics about our traffic,
                  customize our services, and send better ads about our
                  services. These electronic images also may help us measure how
                  effective our email campaigns are by, for instance, counting
                  the number of individuals who visit our website or open an
                  email we send. For example, the Google Analytics service on
                  this website provides reports to us about how many users came
                  to the website, and what links they may have used to get here.
                  (To opt-out of Google Analytics, please go to
                  https://tools.google.com/dlpage/gaoptout.) You can often
                  disable our web beacons by turning images “off” in your email
                  client (e.g., Outlook, Outlook Express). Please see your email
                  client settings for more information. Your browser likewise
                  may permit you to reject or delete cookies, but this may
                  prevent the proper working of our website. You can find more
                  information about cookies and how they work, what cookies have
                  been set on your computer or mobile device and how to manage
                  and delete them at http://www.allaboutcookies.org/
                  and www.youronlinechoices.com/uk. This website does not
                  currently display third-party advertising. We do not respond
                  to browser Do Not Track (DNT) signals at this time.
                </p>
                <p>How We Share Information </p>
                <p>Vendors and other Service Providers</p>
                <p>
                  We share information that we collect or that you provide to us
                  with a range of service providers. These service providers
                  may, for instance, receive information for purposes of tech
                  support, customer support, network and hosting services,
                  billing and collections, email and marketing services
                  (including for data enhancement), customer or website
                  analytics, employment recruiting services, or other operations
                  related to our business. We also work with third-party ad and
                  data vendors who may collect and read unique identifiers
                  associated with browsers and devices, such as cookies: we
                  describe these technologies in more detail above.
                </p>
                <p>Legal Requirements</p>
                <p>
                  We may disclose your information if required to do so by law
                  or in the good faith belief that such action is necessary to
                  (i) comply with a legal obligation, (ii) protect and defend
                  our rights or property, (iii) act in urgent circumstances to
                  protect the personal safety of users of the website or the
                  public, or (iv) protect against legal liability.
                </p>
                <p>De-Identified Information</p>
                <p>
                  In addition, we may share with third parties and/or service
                  providers de-identified information about users’ visits to our
                  website, which may include actual or inferred demographics,
                  interests, and other online habits.
                </p>
                <p>Business Transfers</p>
                <p>
                  If we ever go through a business transition such as a merger,
                  acquisition, or sale of all or a portion of our assets, your
                  personal information and tracking information will likely be
                  among the assets transferred, and that information may also be
                  shared during due diligence for any such transaction. With
                  Your Consent
                </p>
                <p>
                  We may also share any information from or about you with any
                  party when we have your consent.
                </p>
                <p>International Data Transfers </p>
                <p>
                  Personal information may be transferred to, stored, or
                  processed in any country where we or our service providers are
                  based or have facilities. Whenever we transfer your personal
                  information outside of the UK or the European Economic Area,
                  we endeavor to provide similar protection and put in place at
                  least one of these safeguards:
                </p>
                <ul>
                  <li>
                    We will only transfer your personal information to countries
                    that have been found to provide an adequate level of
                    protection for personal information or 
                  </li>
                  <li>
                    We may also use specific approved contracts that use
                    Standard Contractual Clauses for the protection of personal
                    information with our affiliates or service providers that
                    are based in countries outside the UK or the European
                    Economic Area, including those based in the US.
                  </li>
                </ul>
                <p>
                  If you are located in the UK or the European Economic Area,
                  you may contact us for a copy of the safeguards which we have
                  put in place for the transfer of your personal information.{' '}
                </p>
                <p>
                  We may have various legal grounds for using your personal
                  information, including:
                </p>
                <ul>
                  <li>
                    Using your personal information to perform our contract with
                    you (or in order to take steps prior to entering into a
                    contract with you);
                  </li>
                  <li>
                    Using your personal information with your consent (which you
                    can withdraw at any time);
                  </li>
                  <li>Using your personal information to comply with law;</li>
                  <li>
                    Using your personal information if necessary for our
                    legitimate interests in a way that might be expected as part
                    of operating our business and in a way which does not
                    materially impact your rights and freedoms.
                  </li>
                </ul>

                <p>Access</p>

                <p>
                  You may contact us at privacy@hyphametrics.com to update your
                  personal information. 
                </p>

                <p>Your Rights</p>

                <p>
                  You may always opt not to disclose information to us, but keep
                  in mind some information may be needed to use our website.
                </p>
                <p>
                  Our promotional emails contain an easy-to-use opt-out
                  mechanism if you no longer want to receive promotional emails.
                  You may also contact info@hyphametrics.com in order to opt-out
                  of receiving promotional emails. Please note that you may
                  continue to receive administrative emails from us after you
                  opt-out of receiving promotional messages from us.
                </p>

                <p>
                  For options regarding cookies, see “Use of Cookies on Our
                  Website” above. 
                </p>
                <p>
                  If you are a resident of the UK or the European Economic Area,
                  you have a number of rights with regard to your personal
                  information:
                </p>

                <ul>
                  <li>
                    Access: you have the right to request a copy of the personal
                    information we hold about you, Rectification: you have the
                    right to request that we correct the personal information we
                    hold about you,
                  </li>
                  <li>
                    Portability: you have the right to receive a
                    machine-readable copy of your personal information,
                  </li>
                  <li>
                    Deletion: you have the right to ask us to delete your
                    personal information,
                  </li>
                  <li>
                    Restrict: you have the right to ask us to restrict the
                    processing of your personal information in certain
                    circumstances, 
                  </li>
                  <li>
                    Object: you have the right to ask us to stop processing your
                    personal information,
                  </li>
                  <li>
                    Automated decision-making and profiling: you have the right
                    to be free from decisions based on automated processing that
                    produce a significant legal effect on you, unless necessary
                    to perform a contract or you provide consent
                  </li>
                  <li>
                    Withdraw consent: you have the right to withdraw your
                    consent at any time,
                  </li>
                  <li>
                    Lodge complaint: you have the right to lodge a complaint
                    with the data protection authority.
                  </li>
                </ul>

                <p>
                  If you would like to exercise any of your rights specified
                  above, please contact us at the email address or phone number
                  under “Contact Information” below.
                </p>

                <p>Children’s Privacy</p>

                <p>
                  Our websites are not directed to children under the age of 13.
                  We prohibit children under the age of 13 from using all
                  interactive portions of the website. We will not knowingly
                  collect personally identifiable information from children
                  under 13. We understand that young people need special
                  safeguards and privacy protection. We realize that they may
                  not understand all the provisions of our Privacy Policy or be
                  able to make thoughtful decisions about the choices that are
                  made available to our adult users. We strongly urge all
                  parents to participate in their children&#39;s exploration of
                  the Internet and any online services, and to teach their
                  children about protecting their personal information while
                  online. If we learn
                </p>

                <p>
                  we have collected or obtained Personal Information of someone
                  under 13, we will delete that information from our database
                  absent an important and permissible reason to keep it. If you
                  believe that this has occurred, please contact us at
                  privacy@hyphametrics.com.
                </p>
                <p>Data Security</p>
                <p>
                  We have implemented appropriate technical and organizational
                  controls to protect your personal information against
                  unauthorized processing and against accidental loss, damage,
                  or destruction. Although we will do our best to protect your
                  personal information once with us, we cannot guarantee the
                  security of any personal information sent to our website and
                  so you provide it at your own risk.
                </p>
                <p>Changes to this Privacy Policy</p>
                <p>
                  We may make changes to this Privacy Policy at any time, at our
                  sole discretion. Generally, any changes will go into effect on
                  the date they are posted. Should we make material changes to
                  this Privacy Policy, they will go into effect within a
                  reasonable time period from the time they are posted to the
                  website. In our discretion, we may also provide additional
                  reasonable notice through other means, such as through emailed
                  notice. 
                </p>
                <p>
                  Where required by law, we will provide you notice or obtain
                  your consent for changes to this Privacy Policy.
                </p>
                <p>Retention</p>
                <p>
                  We keep your personal information for only as long as we need
                  to use it for the purposes of this Privacy Policy. 
                </p>
                <p>Contact Information</p>
                <p>
                  If you have questions about this Privacy Policy or about our
                  privacy practices, please e-mail privacy@hyphametrics.com.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default PrivacyPolicy
