import React from 'react'

import {Route, Switch, BrowserRouter as Router} from 'react-router-dom'

import store from './store'
import {Provider} from 'react-redux'

//Components
import Home from './components/Home'
import About from './components/About'
import Segment from './components/OpenSegment'
import Contact from './components/Contact'
import ServiceComp from './components/Services'
import ServicesMain from './components/ServicesMain'
import ServicesHover from './components/ServicesHover'
import General from './components/General'
import Dispatches from './components/Dispatches'
import Press from './components/Press'
import EmailSignature from './components/EmailSignature'
import PrivacyPolicy from './components/PrivacyPolicy'
import TermsAndConditions from './components/TermsAndConditions'

class App extends React.Component {
  render() {
    return (
      <>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                exact
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/segment-popup" component={Segment} />
              <Route exact path="/solutions" component={ServicesMain} />
              <Route
                exact
                path="/solutions/:service_name"
                component={ServiceComp}
              />
              {/* <Route exact path="/solutions-menu" component={ServicesMain} /> */}
              <Route exact path="/services-hover" component={ServicesHover} />
              <Route exact path="/solutions" component={General} />
              <Route exact path="/dispatches" component={Dispatches} />
              <Route exact path="/dispatches/:slug" component={Dispatches} />
              <Route exact path="/press" component={Press} />
              <Route
                exact
                path="/ZW1haWwtc2lnbmF0dXJlLWh5cGhhbWV0cmljcw=="
                component={EmailSignature}
              />
            </Switch>
          </Router>
        </Provider>
      </>
    )
  }
}

export default App
