import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../store/action/types";
import { css } from "@emotion/core";
import CircleLoader from "react-spinners/CircleLoader";
import Header from "./includes/Header";
import Footer from "./includes/Footer";

class ServicesMain extends React.Component {
  constructor() {
    super();
    this.state = {
      services: [],
      loading: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    axios
      .get(API_URL + "/solutions-menu")
      .then((result) => {
        if (result.data.success) {
          this.setState({
            services: result.data.services_menu,
            loading: false,
          });
        }
      })
      .catch(function (error) {
        alert("Could not get contents");
        console.log(error);
      });
  }
  render() {
    const override = css`
      display: inline-block;
      border-color: #002a00;
    `;
    const { services } = this.state;
    return (
      <>
        <Header />
        {this.state.loading ? null : ( // </div> //   /> //     loading={this.state.loading} //     color={"#002A00"} //     size={50} //     css={override} //   <CircleLoader // <div className="loader-div">
          <div style={{ padding: "70px 0px 0px" }}>
            <div
              style={{
                backgroundColor: "#000",
                textAlign: "center",
                padding: "2rem 0",
              }}
            >
              <video
                playsInline="playsinline"
                autoPlay
                muted="muted"
                loop
                poster="images/_Roots_2.png"
                style={{ width: "100%", maxHeight: "550px" }}
              >
                <source
                  src="/video/Hypha_Underground_roots.mp4"
                  type="video/mp4"
                />
              </video>
              <div className="solu-vide-text">
                Our data streams offer a range of diverse applications across
                the media ecosystem.
              </div>
              {/* <img
                src="/images/_Roots_2.png"
                alt="Hypha Roots"
                class="img-fluid"
                style={{ maxHeight: 350 }}
              /> */}
            </div>
            <div className="services-main-menu" style={{ padding: 0 }}>
              {services.map((serv, index) => {
                return (
                  <div
                    className="srvmain"
                    style={{ background: serv.title_color }}
                  >
                    <div className="container">
                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{ textAlign: "center" }}
                        >
                          {" "}
                          <Link
                            style={{ color: serv.font_color }}
                            to={"/solutions/" + serv.slug}
                          >
                            {serv.title_2}
                          </Link>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
}
export default ServicesMain;
