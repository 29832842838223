import React from 'react'
import axios from 'axios'
import {API_URL} from '../store/action/types'
import parse from 'html-react-parser'
import {css} from '@emotion/core'
import CircleLoader from 'react-spinners/CircleLoader'
import OwlCarousel from 'react-owl-carousel'
// import 'owl.carousel/dist/assets/owl.carousel.css'
// import 'owl.carousel/dist/assets/owl.theme.default.css'
import Header from './includes/Header'
import Footer from './includes/Footer'
import {Link} from 'react-router-dom'
// import "../assets/css/js_composer.min.css";

class TermsAndConditions extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="abt-tp-content" style={{marginTop: '4rem'}}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4>TERMS &amp; CONDITIONS OF USE</h4>
                <p>
                  By accessing or using the HyphaMetrics website (the
                  “Website”), you indicate that you have read and understand
                  this Terms of Use Agreement (the “Agreement”), which
                  incorporates by reference our Privacy Policy, and agree to be
                  bound by it. You must accept the terms of the Agreement in
                  order to use the Website. If you do not understand or have
                  questions about the Agreement, immediately stop all use of the
                  Website and contact legal@hyphametrics.com. If you do not
                  accept this Terms of Use, you may not use the Website. Your
                  continued use of the Website will constitute your agreement to
                  the most current version of this Terms of Use. If at any time
                  you do not agree to this Terms of Use, you must cease your use
                  of this Website. If you “bookmark” a portion of the Website or
                  otherwise bypass the Terms of Use, you still will be bound by
                  the Terms of Use.
                </p>
                <p>
                  The Terms of Use apply only to the Website and do not apply to
                  any websites that are linked to the Website. For access to the
                  terms and conditions or privacy policies of linked websites,
                  please refer to the policies of such websites. The Terms of
                  Use are subject to change at any time by us, without prior
                  notice to you, by our posting them on the Website. It is your
                  obligation to review the Terms of Use before accessing the
                  Website. Any changes to the Terms of Use will be effective
                  immediately upon our posting them to the Website, unless
                  otherwise stated.
                </p>
                <p>
                  The entire contents of this site are copyrighted by
                  HyphaMetrics, Inc. (“HyphaMetrics”). Reproduction in whole or
                  in part in any form or medium without express written
                  permission of HyphaMetrics is prohibited. HyphaMetrics and
                  Hypha are trademarks of HyphaMetrics. All other trademarks as
                  may appear on this web site are trademarks of either
                  HyphaMetrics or their respective owners.
                </p>
                <h4>USE OF THE WEBSITE</h4>
                <p>
                  The use of the Website and its services is a privilege. Users
                  who violate this Terms of Use may be denied access to the
                  Website and we reserve the right to suspend your use of the
                  Website for any reason at any time, in our sole discretion.
                </p>
                <p>
                  You may not collect or use any portion of the content of this
                  Website in any derivative way, or download, or copy
                  information or other matter for use of any other party. You
                  may not gather information and data on the Website from
                  mining, robots or other extraction tools. The information
                  displayed on the Website may not be used for any purpose
                  except in connection with your direct use of the Website as
                  permitted by this Terms of Use, and may not be excerpted,
                  summarized, duplicated or otherwise removed from the Website
                  except with our explicit, written permission. In addition, you
                  represent, warrant and agree that you will not use (or plan,
                  encourage or help others to use) the Website for any purpose
                  or in any manner that is prohibited by this Terms of Use or by
                  applicable law. You also may not interfere with the proper
                  operation of the Website including, but not limited to, by
                  attacking, hacking into, or otherwise attempting to penetrate
                  any non-publicly accessible elements of the Website or its
                  servers or network, through the use of bots, Trojan Horses,
                  viruses, DNS attacks, or other technology which is designed or
                  intended to interfere with the proper operation of the Website
                  or the use of the Website by any users. You agree that you
                  will not circumvent or attempt to circumvent any security or
                  access control technology implemented on the Website, or the
                  servers and network associated with the Website. Any
                  unauthorized use terminates the permission or license granted
                  by HyphaMetrics, in addition to all rights at law or in
                  equity.
                </p>
                <h4>INTELLECTUAL PROPERTY</h4>
                <p>
                  HyphaMetrics owns any and all intellectual property rights
                  relating to the HyphaMetrics brand, trade name, trade dress,
                  and other content including: copyright, trademark, service
                  mark, trade name, trade dress, proprietary logo, insignia,
                  business identifier, and/or other text and graphics that has
                  or provides the “look and feel” of the HyphaMetrics brand
                  image, as well as all of the text, graphics, photographs,
                  video and audio contained herein (the “Intellectual
                  Property”). Your use of the Website does not grant You any
                  rights or licenses relating to the HyphaMetrics Intellectual
                  Property, including but not limited to any copyrights,
                  trademark rights, patent rights, database rights, moral
                  rights, sui generis rights and other intellectual property and
                  proprietary rights therein, except as expressly provided for
                  in these Terms of Use. All product names, names of services,
                  trademarks and service marks (“Marks”) are the property of
                  their respective owners, as indicated.
                </p>
                <p>
                  None of the Intellectual Property may be used, reproduced,
                  published, transmitted, distributed, displayed, performed,
                  exhibited, modified, used to create derivative works, sold,
                  re-sold or used in any sale, or exploited for in any way, in
                  whole or in part, except as provided for herein and unless you
                  obtain our prior written consent. You may not reproduce,
                  modify, display, sell, or distribute the Content, or use it in
                  any other way for public or commercial purpose. The foregoing
                  limitations include, but are not limited to, copying or
                  adapting the HTML code used to generate web pages on the
                  Website, as well as any graphics.
                </p>
                <h4>DISCLAIMER OF WARRANTIES &amp; LIMITATIONS OF LIABILITY</h4>
                <p>
                  Your agreement to the following disclaimers and limitations
                  are a material inducement for us to permit you to access the
                  HyphaMetrics Website. Your use of the Website, and the
                  obligations and liabilities of us in respect of your use of
                  the Website, is expressly limited as follows:
                </p>
                <h5 style={{fontWeight: 'bold'}}>Disclaimer of Warranties</h5>
                <p>
                  THE WEBSITE IS PROVIDED “AS IS” AND WITHOUT ANY WARRANTY
                  WHATSOEVER. WE DISCLAIM ANY AND ALL EXPRESS AND IMPLIED
                  WARRANTIES WHATSOEVER, INCLUDING THE WARRANTIES OF
                  MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                </p>
                <h5 style={{fontWeight: 'bold'}}>Indemnification</h5>
                <p>
                  You agree to indemnify, defend and hold us harmless from and
                  against any and all liability, claims, causes of actions,
                  damages, costs and expenses, including but not limited to,
                  attorneys fees and costs of suit, arising out of your breach
                  of these Terms and Conditions.
                </p>
                <h5 style={{fontWeight: 'bold'}}>Limitation of Liability</h5>
                <p>
                  IN NO EVENT SHALL HYPHAMETRICS AND ITS SUBSIDIARIES,
                  AFFILIATES, RELATED COMPANIES, SUPPLIERS, ADVERTISERS,
                  SPONSORS, THIRD PARTY SERVICE PROVIDERS, AND EACH OF THEIR
                  RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, SHAREHOLDERS AND
                  AGENTS BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, PUNITIVE,
                  INDIRECT OR SPECIAL DAMAGES, (INCLUDING LOST PROFITS AND
                  DAMAGES OR VIRUSES THAT MAY INFECT YOUR COMPUTER EQUIPMENT ON
                  ACCOUNT OF YOUR ACCESS TO, USE OF, BROWSING, OR DOWNLOADING OF
                  ANY MATERIALS, DATA TEXT, IMAGES, VIDEO OR AUDIO FROM THE
                  WEBSITE) WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY
                  OTHER LEGAL THEORY, AND WHETHER OR NOT HYPHAMETRICS IS ADVISED
                  OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES OR
                  JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE
                  LIMITATIONS SET FORTH IN THIS PARAGRAPH MAY NOT APPLY TO YOU.
                  IF THE FOREGOING LIMITATIONS ARE HELD INAPPLICABLE OR
                  UNENFORCEABLE FOR ANY REASON, THEN THE MAXIMUM LIABILITY OF
                  HYPHAMETRICS TO YOU FOR ANY TYPE OF DAMAGES SHALL BE LIMITED
                  TO $100.00.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default TermsAndConditions
