import React, {Component} from 'react'
import Header from './includes/Header'
import Footer from './includes/Footer'
import {css} from '@emotion/core'
import CircleLoader from 'react-spinners/CircleLoader'

class Press extends Component {
  componentDidMount() {
    this.updateIframeHeight()
    window.$('.abt-bg-top').css({height: '100vh'})
  }
  componentWillReceiveProps() {
    window
      .$('#blogHypha')
      .attr('src', 'https://blog.hyphametrics.com/category/press/')
    this.updateIframeHeight()
  }
  updateIframeHeight = () => {
    window.onmessage = function (e) {
      if (e.data.type && e.data.type === 'iframeHeight') {
        window
          .$('#blogHypha')
          .css({height: e.data.height - 300, visibility: 'visible'})
        window.$('.abt-bg-top').css({height: 'unset'})
      }
    }
  }
  render() {
    return (
      <>
        <Header />
        {/* <div className="abt-bg-top">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-12 nlrp"
                style={{
                  backgroundColor: "#e3e7f4",
                  height: "315px",
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                <video
                  className=""
                  playsInline="playsinline"
                  autoPlay
                  muted="muted"
                  loop
                  poster="images/_Strands_Comp_6.png"
                  style={{ maxHeight: "720px" }}
                >
                  <source src="/video/Strands_Comp_6.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div> */}
        <div className="abt-bg-top" style={{paddingTop: 0, marginTop: 75}}>
          {/* <div className="container">
            <div class="cnt-head">Press</div>
            <h3>Coming Soon</h3>
          </div> */}
          <iframe
            title="press-hypha"
            style={{visibility: 'hidden'}}
            id="blogHypha"
            src="https://blog.hyphametrics.com/category/press/"
            width="100%"
            frameBorder="0"
          />
        </div>

        <Footer />
      </>
    )
  }
}
export default Press
