import React from "react";
import axios from "axios";
import { API_URL } from "../store/action/types";
import { Link } from "react-router-dom";
import CircleLoader from "react-spinners/CircleLoader";
import { css } from "@emotion/core";
import ReactHtmlParser from "react-html-parser";

class ServicesHover extends React.Component {
  constructor() {
    super();
    this.state = {
      service: {},
      services_features: [],
      s_timeline_main: [],
      color: "#000000",
      loading: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios
      .get(API_URL + "/services/agencies")
      .then(async (result) => {
        if (result.data.success) {
          await this.setState({
            service: result.data.services,
            services_features: result.data.services_features,
            s_timeline_main: result.data.s_timeline_main,
            color: result.data.services.title_color,
            loading: false,
          });
          window.$(".nav-item").click(function () {
            window
              .$(".srv-tabs")
              .attr("selectedNav", window.$(this).attr("href"));
          });
          window.$(".nav-item").hover(
            function () {
              window.$(window.$(this).attr("href")).addClass("active show");
            },
            function () {
              window.$(window.$(this).attr("href")).removeClass("active show");
              window
                .$(window.$(".srv-tabs").attr("selectedNav"))
                .addClass("active show");
              window
                .$(window.$(".srv-tabs").attr("selectedNav"))
                .addClass("active show");
            }
          );
        }
      })
      .catch(function (error) {
        alert("Something went wrong");
        this.setState({
          loading: false,
        });
      });
  }
  render() {
    const {
      loading,
      service,
      color,
      services_features,
      s_timeline_main,
    } = this.state;
    const numRows = services_features.length;
    const override = css`
      display: inline-block;
      border-color: #002a00;
      margin-left: 45%;
      margin-top: 13%;
      margin-bottom: 8%;
    `;
    return (
      <>
        <style>
          {`.tabber-agency nav > div a.nav-item.nav-link.active .tab-inner {
            border: none;
            padding: 12px 0px;
            display: block;
            color: #002a00;
            background: transparent;
            width: 75px;
            height: 75px;
            margin: 0 auto;
            border-radius: 50%;

            border: 10px solid ${color};
          }

          .tabber-agency nav > div a.nav-item.nav-link.active:after {
            content: "";
            position: relative;
            bottom: 0px;
            left: 0px;
            /* width: 10px; */
            /* height: 10px; */
            border: 5px solid transparent;

            background: ${color};
            border-top-color: ${color};
          }
          .tabber-agency .tab-content {
            background: transparent;
            line-height: 25px;
            border: 0px solid #ddd;

            border-top: 0px
            border-bottom: 0px
            padding: 0px 25px;
          }
          .tabber-agency nav > div a.nav-item.nav-link:hover .tab-inner {
            border: none;
            width: 75px;
            height: 75px;
            padding: 12px 0px;
            color: #002a00;
            border-radius: 50%;
            margin: 0 auto;
            transition: background 0.2s linear;
            border: 10px solid ${color};
          }
          .tabber-agency nav > div a.nav-item.nav-link:focus .tab-inner {
            border: none;
            width: 75px;
            height: 75px;
            padding: 12px 0px;
            color: #002a00;
            border-radius: 50%;
            margin: 0 auto;
            transition: background 0.2s linear;
            border: 10px solid ${color};
          }`}
        </style>
        <div>
          <div
            className="services-section"
            style={{ borderBottom: `1px solid ${color}` }}
          >
            <div className="container">
              <div className="row">
                <div className="srv-head">
                  <h2>
                    Agencies
                    <span>
                      <Link
                        to="/services-menu"
                        style={{ color: "#002a00", textDecoration: "none" }}
                      >
                        ▼
                      </Link>
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {/*- Services Content Heading --*/}
          {this.loading ? null : (
            // <CircleLoader
            //   css={override}
            //   size={50}
            //   color={"#002A00"}
            //   loading={this.state.loading}
            // />
            <>
              <div
                className="services-intro"
                style={{ borderBottom: `1px solid ${color}` }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <p>{ReactHtmlParser(service.description_2)}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="srv-hd-img">
                        <img src={service.image} className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*-- Services Points ---*/}
              <div
                className="services-points"
                style={{ borderBottom: `1px solid ${color}` }}
              >
                <div className="container">
                  {services_features.map((features, index) => {
                    return (
                      <>
                        {numRows !== ++index ? (
                          <div
                            className="row srv-pointer"
                            style={{
                              borderBottom: `1px solid ${color}`,
                            }}
                          >
                            <div className="col-md-6">
                              <h2>{features.title}</h2>
                            </div>
                            <div className="col-md-6">
                              {ReactHtmlParser(features.description)}
                            </div>
                          </div>
                        ) : (
                          <div className="row srv-pointer">
                            <div className="col-md-6">
                              <h2>{features.title}</h2>
                            </div>
                            <div className="col-md-6">
                              {ReactHtmlParser(features.description)}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              {/*-- Services Points ---*/}
              <div className="srv-tabs" selectedNav="">
                {s_timeline_main.map((main, pindex) => {
                  return (
                    <div
                      className="container-fluid srv-tabs-row"
                      style={{ borderBottom: `1px solid ${color}` }}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="srv-tb-sec">
                              <h2>{main.title}</h2>
                              <p>{ReactHtmlParser(main.description)}</p>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="row tabber-agency">
                              <div className="col-md-12 ">
                                <nav>
                                  <div
                                    className="nav nav-tabs nav-fill"
                                    id="nav-tab"
                                    role="tablist"
                                  >
                                    {main.services_details.map(
                                      (timeline, tindex) => {
                                        return (
                                          <a
                                            className={`nav-item nav-link${
                                              pindex === tindex ? " active" : ""
                                            }`}
                                            id="nav-home-tab"
                                            data-toggle="tab"
                                            href={`#p${pindex}-t${tindex}`}
                                            role="tab"
                                            aria-controls="nav-home"
                                            aria-selected="true"
                                          >
                                            <div className="tab-inner">
                                              {timeline.time}
                                            </div>
                                          </a>
                                        );
                                      }
                                    )}
                                  </div>
                                </nav>
                                <div
                                  className="tab-content px-3 px-sm-0"
                                  id="nav-tabContent"
                                >
                                  {main.services_details.map(
                                    (detail, tindex) => {
                                      return (
                                        <div
                                          className={`tab-pane fade${
                                            pindex === tindex
                                              ? " show active"
                                              : ""
                                          }`}
                                          id={`p${pindex}-t${tindex}`}
                                          role="tabpanel"
                                          aria-labelledby="nav-home-tab"
                                        >
                                          <div
                                            style={{
                                              background: color,
                                              height: "10px",
                                              borderRadius: "8px",
                                              marginBottom: "25px",
                                            }}
                                          />
                                          <div className="row tab-btn">
                                            {detail.image_exist == 1 ? (
                                              <>
                                                <div className="col-md-6">
                                                  {ReactHtmlParser(
                                                    detail.description_1
                                                  )}
                                                </div>
                                                <div className="col-md-6">
                                                  {ReactHtmlParser(
                                                    detail.description_2
                                                  )}
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="col-md-5">
                                                  {ReactHtmlParser(
                                                    detail.description_1
                                                  )}
                                                </div>
                                                <div className="col-md-5">
                                                  {ReactHtmlParser(
                                                    detail.description_2
                                                  )}
                                                </div>
                                                <div className="col-md-2">
                                                  <img src={detail.image} />
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                ;
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default ServicesHover;
