import React from 'react'
import {connect} from 'react-redux'
import {setApiLoaded} from '../store/action/videoAction'
import {setHeadVideoLoaded} from '../store/action/headVideoAction'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {API_URL} from '../store/action/types'
import Anchor from '../components/includes/Anchor'
import parse from 'html-react-parser'
import {css} from '@emotion/core'
import CircleLoader from 'react-spinners/CircleLoader'
import Header from './includes/Header'
import Footer from './includes/Footer'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      videoLoaded: props.videoLoaded,
      headVideo: props.headVideo,
      displayMenu: 'none',
      audience: false,
      audience_loader: true,
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    if (!this.state.videoLoaded.isLoaded) {
      document.body.style.overflow = 'hidden'
      document
        .getElementById('myVideo')
        .addEventListener('ended', this.myHandler, false)
    }
    if (!this.state.videoLoaded.isLoaded) {
      axios
        .get(API_URL + '/home')
        .then(result => {
          if (result.data.success) {
            this.setState(
              {
                loading: false,
              },
              () => {
                this.props.setApiLoaded({
                  isLoaded: true,
                  videoName: result.data.home.image,
                  homeData: result.data.home,
                  servPopup: result.data.services,
                })
              },
            )
          }

          // console.log(result.data.home)
        })
        .catch(error => {
          alert('Could not get contents')
          this.setState({
            loading: false,
          })
        })
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  myHandler = e => {
    this.setState({loader: 'done'}, () => {
      document.body.style.overflow = 'auto'
      this.setState(
        {
          // loading: false
        },
        () => {
          this.props.setHeadVideoLoaded({
            doneHeaderVideo: true,
            headerVideoName:
              'https://backend.hypha.clickysoft.us/public/home/single.mp4',
          })
        },
      )
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.videoLoaded) {
      this.setState({
        videoLoaded: nextProps.videoLoaded,
      })
    }
    if (nextProps.headVideo) {
      this.setState({
        headVideo: nextProps.headVideo,
      })
    }
  }

  showHideDD = () => {
    if (this.state.displayMenu === 'none') {
      this.setState({
        displayMenu: 'block',
      })
    } else {
      this.setState({
        displayMenu: 'none',
      })
    }
  }

  interestedText = () => {
    this.setState({
      audience: false,
    })
  }

  showHideDescription = slug => {
    console.log(slug)
    this.showHideDD()
    // if (this.state.audience === false) {
    axios
      .get(API_URL + '/segment/' + slug)
      .then(result => {
        if (result.data.success) {
          console.log(result)
          this.setState({
            segment_details: result.data.segment_details,
            audience: true,
            audience_loader: false,
          })
        }
      })
      .catch(function (error) {
        alert('Could not get contents')
        this.setState({
          audience: false,
          audience_loader: false,
        })
      })
    // }
  }

  render() {
    const override = css`
      display: inline-block;
      border-color: #002a00;
    `
    const {videoLoaded, displayMenu, segment_details, headVideo} = this.state

    return (
      <>
        {!this.state.headVideo.doneHeaderVideo ? (
          <video
            width={'100%'}
            playsInline="playsinline"
            autoPlay
            muted="muted"
            id="myVideo"
          >
            <source src="video/header_video.m4v" type="video/mp4" />
          </video>
        ) : (
          <>
            <Header />
            <div className="homepage">
              <div className="" style={{textAlign: 'center', padding: '0'}}>
                {/* <div className="video-background-overlay" /> */}
                <video
                  playsInline="playsinline"
                  autoPlay
                  muted="muted"
                  loop
                  key={videoLoaded.videoName}
                  className="video-hypha-data"
                >
                  <source
                    src="/video/Hypha_Toroid.mp4"
                    // src={
                    //   "https://backend.hypha.clickysoft.us/public/home/single.mp4"
                    // }
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="hm-lm d-flex justify-content-center align-content-center">
              <h1
                className="align-self-center"
                style={{
                  color: '#002A00',
                  fontSize: '2.5rem',
                  lineHeight: '100px;',
                }}
              >
                {videoLoaded.homeData.image_title}
              </h1>
            </div>
            <section className="hm-about">
              <div className="container">
                <div className="row">
                  {/* <div className="col-lg-12 mx-auto abt-cnt">
                    <div className="d-flex h-100 text-center align-items-center">
                      <div className="w-100 text-white">
                        <h1
                          className="display-4"
                          style={{
                            color: "#ccff00",
                            "fontSize": "72px",
                            "lineHeight": "100px;",
                          }}
                        >
                          {videoLoaded.homeData.image_title}
                        </h1>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-12 mx-auto abt-cnt">
                    {/* <p className="lead">{videoLoaded.homeData.description_1}</p> */}
                    <p className="lead">
                      HyphaMetrics provides a unified understanding of media
                      behavior that evolves at the speed of culture. We serve as
                      the objective technology standard globally for the precise
                      measurement of media at the individual level. Using
                      Artificial Intelligence and Machine learning to analyze
                      and optimize advertising and video content for media
                      executives overseeing content, ads, brand sponsorships,
                      and product placements, we cost-efficiently support
                      interoperability across the entire media ecosystem serving
                      measurement companies, brands, agencies, and media
                      publishers.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <div className="hm-lm2">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="hm-lm-link">
                      <Anchor
                        linkType={videoLoaded.homeData.btn_link_type_1}
                        link={videoLoaded.homeData.btn_link_1}
                        text={videoLoaded.homeData.btn_text_1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hm-lmore">
              {!this.state.loading ? (
                <>
                  {' '}
                  {!this.state.audience ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-9">
                          {/* {videoLoaded.homeData.services_heading_1}{" "} */}
                          <Link
                            className="interested-dd"
                            onClick={() => {}}
                            style={{cursor: 'text'}}
                          >
                            {videoLoaded.homeData.services_heading_2}
                            {/* <span>▼</span> */}
                            {/*  */}
                            <div
                              className="seg-links-main"
                              style={{
                                display: displayMenu,
                              }}
                            >
                              {videoLoaded.servPopup.map((segment, index) => {
                                return (
                                  <div className="row" key={index}>
                                    <div className="col-md-12">
                                      {segment.slug === 'general' ? (
                                        <Link
                                          to={'solutions/' + segment.slug}
                                          style={{
                                            paddingBottom: '8px',
                                            borderBottom:
                                              '4px solid ' +
                                              segment.title_color,
                                          }}
                                        >
                                          {segment.title_1}
                                        </Link>
                                      ) : (
                                        <Link
                                          onClick={this.showHideDescription.bind(
                                            this,
                                            segment.slug,
                                          )}
                                          style={{
                                            paddingBottom: '8px',
                                            borderBottom:
                                              '4px solid ' +
                                              segment.title_color,
                                          }}
                                        >
                                          {segment.title_1}
                                        </Link>
                                      )}
                                      {/* */}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                            {/*  */}
                          </Link>
                        </div>
                        <div className="col-md-3">
                          <div className="hm-lm-go">
                            <Anchor
                              linkType={videoLoaded.homeData.btn_link_type_2}
                              link={videoLoaded.homeData.btn_link_2}
                              text={videoLoaded.homeData.btn_text_2}
                            />
                            {/* <Link>{home.btn_text_2} →</Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {' '}
                      {this.state.audience_loader ? null : (
                        // <div className="loader-div">
                        //   <CircleLoader
                        //     css={override}
                        //     size={50}
                        //     color={"#002A00"}
                        //     loading={this.state.audience_loader}
                        //   />
                        // </div>
                        <div className="container">
                          {/* <div className="row">
                    <div className="col-md-12">

                      <div className="hm-bk-btn">
                        <div className="hm-lm-go">
                          <Link onClick={this.interestedText} >Back</Link>

                        </div>
                      </div>

                    </div>
                  </div> */}
                          <div className="row">
                            <div className="col-md-12">
                              {' '}
                              I am:{' '}
                              <Link
                                // to={"/solutions/" + segment_details.slug}
                                // onClick={this.showHideDD}
                                onClick={() => {}}
                                style={{
                                  paddingBottom: '8px',
                                  borderBottom:
                                    '4px solid' + segment_details.title_color,
                                  color: segment_details.title_color,
                                }}
                              >
                                {parse(String(segment_details.desc_segment_1))}{' '}
                                <span style={{color: 'black'}}>▼</span>
                              </Link>{' '}
                              {/* <Link
                                onClick={this.interestedText}
                                className="btn-back"
                              >
                                <i className="fa fa-arrow-left"></i>
                              </Link> */}
                              seeking to{' '}
                              <Link
                                // to={"/solutions/" + segment_details.slug}
                                // onClick={this.showHideDD}
                                onClick={() => {}}
                                style={{
                                  paddingBottom: '8px',
                                  borderBottom:
                                    '4px solid' + segment_details.title_color,
                                  color: segment_details.title_color,
                                }}
                              >
                                {parse(String(segment_details.desc_segment_2))}
                              </Link>
                              <div
                                className="seg-links-main"
                                style={{
                                  display: displayMenu,
                                  // position: "absolute",
                                  zIndex: 999,
                                }}
                              >
                                {videoLoaded.servPopup.map((segment, index) => {
                                  return (
                                    <div className="row" key={index}>
                                      <div className="col-md-12">
                                        {segment.slug === 'general' ? (
                                          <Link
                                            to={'solutions/' + segment.slug}
                                            style={{
                                              paddingBottom: '8px',
                                              borderBottom:
                                                '4px solid ' +
                                                segment.title_color,
                                            }}
                                          >
                                            {segment.title_1}
                                          </Link>
                                        ) : (
                                          <Link
                                            onClick={this.showHideDescription.bind(
                                              this,
                                              segment.slug,
                                            )}
                                            style={{
                                              paddingBottom: '8px',
                                              borderBottom:
                                                '4px solid ' +
                                                segment.title_color,
                                            }}
                                          >
                                            {segment.title_1}
                                          </Link>
                                        )}
                                        {/* */}
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="hm-lm-go">
                                <Link to={'/solutions/' + segment_details.slug}>
                                  {segment_details.btn_text}
                                </Link>
                                {/* <Anchor
                          linkType={1}
                          link={'/solutions/' + segment_details.slug}
                          text={segment_details.btn_text}
                        /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                ''
              )}
            </div>
            <Footer />
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  videoLoaded: state.videoLoaded,
  headVideo: state.headVideo,
})
const mapDispatchToProps = {
  setApiLoaded,
  setHeadVideoLoaded,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
