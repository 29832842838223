import { HEADER_VIDEO } from "../action/types";

const initialState = {
    doneHeaderVideo : false,
    headerVideoName : "https://backend.hypha.clickysoft.us/public/home/single.mp4"
};

export default (state = initialState , action) =>
{
    switch(action.type)
    {
        case HEADER_VIDEO:
            return {
                ...state,
                doneHeaderVideo : action.payload.doneHeaderVideo,
                headerVideoName : action.payload.headerVideoName
            }
        default:
            return state;
    }
}