import React, {Component} from 'react'

import Header from './includes/Header'
import Footer from './includes/Footer'

const BASE_URL_BLOG = 'https://blog.hyphametrics.com'

class Dispatches extends Component {
  iframeRef = React.createRef()

  componentDidMount() {
    this.updateIframeHeight()
    window.$('.abt-bg-top').css({height: '100vh'})

    const slug = this.props.match.params.slug
    let url = BASE_URL_BLOG
    if (slug) {
      url = `${url}/${slug}`
    }
    window.$('#blogHypha').attr('src', url)
  }

  componentWillReceiveProps() {
    this.updateIframeHeight()
  }

  componentDidUpdate() {
    const slug = this.props.match.params.slug
    if (!slug) {
      window.$('#blogHypha').attr('src', BASE_URL_BLOG)
    }
  }

  updateIframeHeight = () => {
    window.onmessage = function (e) {
      if (e.data.type && e.data.type === 'iframeHeight') {
        window
          .$('#blogHypha')
          .css({height: e.data.height - 300, visibility: 'visible'})
        window.$('.abt-bg-top').css({height: 'unset'})
      }
    }
  }

  onChangeIframeSrc = e => {
    try {
      if (document.URL.includes('hyphametrics.com')) {
        const slugUrl = this.props.match.params.slug
        const slugIframe =
          this.iframeRef.current.contentWindow.document.URL.replace(
            BASE_URL_BLOG + '/',
            '',
          )
        if (slugIframe && slugUrl !== slugIframe) {
          this.props.history.replace(`/dispatches/${slugIframe}`)
        }
      }
    } catch (e) {
      console.log({e})
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="abt-bg-top" style={{paddingTop: 0, marginTop: 75}}>
          <iframe
            title="blog-hypha"
            ref={ref => (this.iframeRef.current = ref)}
            style={{visibility: 'hidden'}}
            id="blogHypha"
            width="100%"
            frameBorder="0"
            onLoad={this.onChangeIframeSrc}
          />
        </div>

        <Footer />
      </>
    )
  }
}
export default Dispatches
